export enum UserDocumentCDDEnum {
  Cv = 'document.cv',
  ProofSocialSecurity = 'document.proof_social_security',
  ProofAddress = 'document.proof_address',
  Diploma = 'document.diploma',
  ExtractCriminalRecord = 'document.extract_criminal_record',
  VaccinationRecord = 'document.vaccination_record',
  FrenchPracticeAuthorization = 'document.french_practice_authorization',
  PersonalIBAN = 'document.personal_iban',
  DrivingLicense = 'document.driving_license',
}

export type UserDocumentEntity = { name: UserDocumentCDDEnum, id: number, required: boolean };

export type UserDocument = {
  entity: UserDocumentEntity,
  createdAt: string | undefined
};
